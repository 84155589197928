import { Text } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";
import { APP_V2_USER_EVENTS } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { useLocation } from "react-router-dom";

import { Button } from "../../components/Button";
import { PageHeaderWrapper } from "../../components/PageHeaderWrapper";
import { DEFAULT_SHIFT_DISCOVERY_DATE_RANGE_WINDOW_IN_DAYS } from "../../utils/getShiftDiscoveryDefaultDateRange";
import { SHIFT_DISCOVERY_WORKPLACES_LIST_MODAL_PATH } from "../paths";
import { useWorkplaceModalsDataContext } from "../useWorkplaceModalsDataContext";

export function MapViewPageHeader() {
  const location = useLocation();
  const { generateModalPath } = useWorkplaceModalsDataContext();

  const workplacesListPath = `${generateModalPath(SHIFT_DISCOVERY_WORKPLACES_LIST_MODAL_PATH)}${
    // forward the search params to the workplaces list modal
    location.search
  }`;

  return (
    <PageHeaderWrapper variant="tertiary" sx={{ paddingBottom: 1 }}>
      <Stack
        direction="row"
        sx={{ paddingBottom: 3, px: 5 }}
        spacing={7}
        alignItems="center"
        justifyContent="space-between"
      >
        <Button
          fullWidth
          variant="outlinedHeavy"
          size="medium"
          aria-label="Workplaces Search"
          startIconType="search"
          sx={(theme) => ({
            gap: 2,
            px: 4,
            justifyContent: "flex-start",

            ".MuiButton-startIcon": {
              marginRight: 0,
              "& svg": {
                width: theme.size?.iconButton.medium.iconSize,
                height: theme.size?.iconButton.medium.iconSize,
              },
            },
          })}
          href={workplacesListPath}
          onClick={() => {
            logEvent(APP_V2_USER_EVENTS.BROWSE_WORKPLACES_OPENED);
          }}
        >
          <Text semibold variant="body2">
            Browse all workplaces
          </Text>
        </Button>

        <Text
          semibold
          variant="caption"
          color={(theme) => theme.palette.text.secondary}
          sx={{ textWrap: "nowrap" }}
        >
          All shifts within {DEFAULT_SHIFT_DISCOVERY_DATE_RANGE_WINDOW_IN_DAYS} days
        </Text>
      </Stack>
    </PageHeaderWrapper>
  );
}
