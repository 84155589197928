/* eslint-disable import/max-dependencies */
import { Stack } from "@mui/material";
import { GoogleMap } from "@react-google-maps/api";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";
import { convertFromGoogleMapsLocation, convertToGoogleMapsLocation } from "@src/appV2/Location";
import { USER_EVENTS } from "@src/constants";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useHistory } from "react-router-dom";

import { GoogleMapCustomMarkerWrapper } from "../../GeoLocation/GoogleMapCustomMarkerWrapper";
import { MapMarkerCounter } from "../../GeoLocation/MapMarkerCounter";
import { MapMarkerLocation } from "../../GeoLocation/MapMarkerLocation";
import { useGeoLocationFromUrlParams } from "../../GeoLocation/useGeoLocationFromUrlParams";
import { ShiftDiscoveryCalloutsContainer } from "../CalloutsContainer";
import { DEFAULT_DISTANCE_IN_MILES_FILTER } from "../Filters/constants";
import {
  SHIFT_DISCOVERY_ENABLE_LOCATION_PERMISSIONS_PATH,
  SHIFT_DISCOVERY_WORKPLACE_OPEN_SHIFTS_MODAL_PATH,
} from "../paths";
import { ShiftDiscoverySearchModeSwitcher } from "../SearchModeSwitcher";
import { useShiftModalsDataContext } from "../useShiftModalsDataContext";
import { useWorkplaceModalsDataContext } from "../useWorkplaceModalsDataContext";
import { DEFAULT_MAP_ZOOM, googleMapOptions } from "./constants";
import { ShiftDiscoveryMapViewEmptyStateCard } from "./EmptyStateCard";
import { ShiftDiscoveryMapViewMapControls } from "./MapControls";
import { useDebugMapViewLocationFilter } from "./mapViewDebuggingUtils/useDebugMapViewLocationFilter";
import { MapViewPageHeader } from "./PageHeader";
import { useLocationFilter } from "./useLocationFilter";
import { useMapFitOpenShiftsBounds } from "./useMapFitOpenShiftsBounds";
import {
  invalidateMapViewWorkplacesData,
  useMapViewWorkplacesData,
} from "./useMapViewWorkplacesData";
import { useWorkerLocations } from "./useWorkerLocations";
import { MapViewWorkplaceClusterer } from "./WorkplaceClusterer/Clusterer";

export function ShiftDiscoveryMapViewPage() {
  const [map, setMap] = useState<google.maps.Map | undefined>(undefined);
  const queryClient = useQueryClient();

  const history = useHistory();
  const { navigateToModal } = useShiftModalsDataContext();
  const { generateModalPath: generateWorkplaceModalPath } = useWorkplaceModalsDataContext();
  const { geoLocation: geoLocationFromUrlParams } = useGeoLocationFromUrlParams();

  const { workerLocation, deviceLocation, homeLocation, shouldRequestLocationPermission } =
    useWorkerLocations();

  const workerGeoLocation = workerLocation
    ? convertFromGoogleMapsLocation(workerLocation)
    : undefined;

  const currentGeoLocation = geoLocationFromUrlParams ?? workerGeoLocation;

  const {
    data: { mapViewWorkplaces, priorityShiftsCount, urgentShiftsCount, geoLocationFilter },
    isLoading,
    isSuccess,
    fetchMore,
  } = useMapViewWorkplacesData({
    geoLocation: currentGeoLocation,
  });

  const { onChangeLocationFilter, onChangeLocationFilterAfterDistance } =
    useLocationFilter(fetchMore);

  useMapFitOpenShiftsBounds(mapViewWorkplaces, map);

  useLogEffect(USER_EVENTS.VIEW_OPEN_SHIFT_MAP, { trigger: "pageLoad" });

  function onWorkplaceClick(workplaceId: string) {
    logEvent(APP_V2_USER_EVENTS.WORKPLACE_OPEN_SHIFTS_OPENED, {
      workplaceId,
      trigger: "Shift Discovery Map View",
    });

    history.push(
      generateWorkplaceModalPath(SHIFT_DISCOVERY_WORKPLACE_OPEN_SHIFTS_MODAL_PATH, {
        workplaceId,
      })
    );
  }

  useDebugMapViewLocationFilter({
    map,
    radiusInMiles: DEFAULT_DISTANCE_IN_MILES_FILTER,
    locationFilter: geoLocationFilter,
    /**
     * Enable debugging for drawing a circle around the location filter.
     * Useful for visualizing the radius used for fetching workplaces,
     * and checking if it's correctly triggering new fetches when the user moves the map.
     */
    enabled: false,
  });

  return (
    <Stack
      sx={(theme) => ({
        flex: 1,
        backgroundColor: theme.palette.background.tertiary,
        overflow: "hidden",
        position: "relative",
      })}
    >
      <MapViewPageHeader />

      <Stack sx={{ position: "relative", flex: 1 }}>
        <GoogleMap
          mapContainerStyle={{ width: "100%", height: "100%" }}
          options={googleMapOptions}
          onLoad={(map) => {
            setMap(map);
            if (currentGeoLocation) {
              const latLng = convertToGoogleMapsLocation(currentGeoLocation);
              map.setCenter(latLng);
              onChangeLocationFilter(latLng);
            }
          }}
          onUnmount={() => {
            setMap(undefined);
          }}
          onDragEnd={() => {
            onChangeLocationFilterAfterDistance(map?.getCenter());
          }}
        >
          {workerLocation && (
            <GoogleMapCustomMarkerWrapper position={workerLocation}>
              <MapMarkerLocation />
            </GoogleMapCustomMarkerWrapper>
          )}

          <MapViewWorkplaceClusterer enabled={false} workplaces={mapViewWorkplaces} map={map}>
            {(workplace, position) => {
              const { rating } = workplace.attributes;
              return (
                <GoogleMapCustomMarkerWrapper key={workplace.id} position={position}>
                  <MapMarkerCounter
                    count={workplace.shiftsCount}
                    onClick={() => {
                      logEvent(USER_EVENTS.TAP_FACILITY_PIN, {
                        workplaceId: workplace.id,
                        rating: rating.value,
                      });
                      onWorkplaceClick(workplace.id);
                    }}
                  />
                </GoogleMapCustomMarkerWrapper>
              );
            }}
          </MapViewWorkplaceClusterer>
        </GoogleMap>

        <ShiftDiscoveryMapViewMapControls
          canCenterToDevice={(!!deviceLocation && !!map) || shouldRequestLocationPermission}
          canCenterToHome={!!homeLocation && !!map}
          isLoading={isLoading}
          onCenterToHome={() => {
            if (map && homeLocation) {
              map.panTo(homeLocation);
              map.setZoom(DEFAULT_MAP_ZOOM);
              void invalidateMapViewWorkplacesData(queryClient);
              onChangeLocationFilter(homeLocation);
            }
          }}
          onCenterToDevice={() => {
            if (!deviceLocation && shouldRequestLocationPermission) {
              navigateToModal(SHIFT_DISCOVERY_ENABLE_LOCATION_PERMISSIONS_PATH);
              return;
            }

            if (map && deviceLocation) {
              map.panTo(deviceLocation);
              map.setZoom(DEFAULT_MAP_ZOOM);
              void invalidateMapViewWorkplacesData(queryClient);
              onChangeLocationFilter(deviceLocation);
            }
          }}
          onZoomIn={() => {
            const currentZoom = map?.getZoom();
            if (map && currentZoom) {
              map.setZoom(currentZoom + 1);
            }
          }}
          onZoomOut={() => {
            const currentZoom = map?.getZoom();
            if (map && currentZoom) {
              map.setZoom(currentZoom - 1);
            }
          }}
        >
          <ShiftDiscoveryCalloutsContainer
            priorityShiftsCount={priorityShiftsCount}
            urgentShiftsCount={urgentShiftsCount}
            sx={{ marginTop: 2, px: 5 }}
          />
        </ShiftDiscoveryMapViewMapControls>

        <ShiftDiscoveryMapViewEmptyStateCard
          isVisible={
            isSuccess &&
            (mapViewWorkplaces?.length === 0 ||
              mapViewWorkplaces?.every((workplace) => workplace.shiftsCount === 0))
          }
        />
      </Stack>

      <ShiftDiscoverySearchModeSwitcher />
    </Stack>
  );
}
/* eslint-enable import/max-dependencies */
